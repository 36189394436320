/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/style-font.scss";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "poppins-regular" !important;
}

b {
  font-family: "poppins-bold" !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cursor-pointer {
  cursor: pointer;
}

.text-white {
  color: white !important;
}

.text-gray {
  color: #707070 !important;
}

.text-black {
  color: black !important;
}

.text-blue {
  color: #0058FF !important;
}

.text-red {
  color: #FF0020 !important;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.h-100 {
  height: 100%;
}

.h-80 {
  height: 80%;
}

.h-60 {
  height: 60%;
}

.h-40 {
  height: 40%;
}

.h-20 {
  height: 20%;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
  ;
}

.fs-24 {
  font-size: 24px !important;
}

.fit-size {
  height: inherit;
  width: inherit;
  object-fit: cover;
  object-position: center;
}

.original-size {
  max-width: 100% !important;
  max-height: 100% !important;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.position-relative {
  position: relative;
}

app-detail-club,
app-detail-inliver {
  .p-tabmenu .p-tabmenu-nav {
    background-color: transparent !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: transparent !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent !important;
  }
}

p-table {
  .p-datatable .p-datatable-thead>tr>th {
    background: #e6e9f4 !important;
  }
}

app-list-club-scenes {
  .p-dialog-header {
    justify-content: center !important;
  }
}

app-card-post {
  .p-image-preview-container>img {
    height: 100px;
  }
}
